<script setup lang="ts">
import { AuthLoginData } from '~~/plugins/auth';
import LineHorizontalGradient2 from '~~/assets/svg/lines/horizontal-gradient-2.svg';
import { useNotificationStore } from '~~/store/notification';

const notificationStore = useNotificationStore();

const submit = async () => {
  const response = await $auth.login(input.value);
  router.push((route.query.redirectTo as string) || '/');

  if (response && response.data.data.coin) {
    let totalCoins = 0;
    response.data.data.coin.forEach((coin) => {
      totalCoins += coin.amount;
    });

    notificationStore.success('', {
      desc: totalCoins,
    });
  }
};

const { $auth } = useNuxtApp();
const { input, submitting, errors, handleSubmit } =
  useForm<AuthLoginData>(submit);
const router = useRouter();
const route = useRoute();

definePageMeta({ middleware: ['guest'] });
useHead({ title: 'Login' });
</script>

<template>
  <main class="container lg:max-w-[480px] pt-80 lg:pt-[112px]">
    <h1 class="text-32 leading-40 text-shadow">Login</h1>
    <p class="opacity-75 mb-8">To The Infinite Coolness!</p>
    <LineHorizontalGradient2 class="mb-16" />

    <form class="mb-12" @submit.prevent="handleSubmit">
      <div class="mb-12">
        <label for="email">Email</label>
        <input
          id="email"
          type="email"
          class="form-control"
          placeholder="Your email"
          autocomplete="email"
          v-model="input.email"
          :class="{ 'is-invalid': errors.email }"
        />
        <div class="invalid-feedback">{{ errors.email }}</div>
      </div>

      <div class="mb-12">
        <div class="flex gap-16 justify-between">
          <label for="password">Password</label>
          <RouterLink to="forgot-password">Forgot Password</RouterLink>
        </div>
        <FormPasswordField
          v-model="input.password"
          :inputAttrs="{
            id: 'password',
            placeholder: 'Your password',
            autocomplete: 'current-password',
          }"
          :class="{ 'is-invalid': errors.password }"
        />
        <div class="invalid-feedback">{{ errors.password }}</div>
      </div>

      <div class="mb-12">
        <input
          class="form-check-input"
          type="checkbox"
          id="remember"
          v-model="input.remember"
        />
        <label for="remember"> Remember Me </label>
      </div>

      <button
        class="btn btn-primary btn-416x48"
        :class="{ 'cursor-wait': submitting }"
        :disabled="submitting"
      >
        Login
      </button>
    </form>

    <hr class="border-primary opacity-40 mb-12" />
    <p class="font-bold mb-8">Don't have an account?</p>

    <RouterLink class="btn btn-secondary btn-416x48" to="/register">
      Sign Up
    </RouterLink>
  </main>
</template>
